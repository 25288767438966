import { faSearch, faFire } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/header.css";
import {Link} from 'react-router-dom'
import React,{useContext} from "react";
import { SearchContext } from "./SearchContext";
function Header() {
  const [ query, setQuery] = useContext(SearchContext)

  const handleonsearch =(e)=>{
    const value = e.target.value;
      setQuery(value)
  
  }

  return (
    <header className="container">
      <div className="grouplogo glhstyle">
        <FontAwesomeIcon icon={faFire} className="logoiconstyle" />
        <div className="logoname">KAMIYAB</div>
        <div className="logosecondname">BAKERY</div>
      </div>
      <h1 className="titlefirstpage">
        <FontAwesomeIcon icon={faFire} />
        <Link to="/adminpanel">Kamiyab Bakery</Link>
      </h1>
      <div className="groupsearchbar">
        <input
          type="text"
          placeholder="Enter Keyword Here...."
          autoComplete="off"
          autoSave="off"
          name="search"
          className="searchbar"
          value={query}
          onChange={handleonsearch}
        />
        <button className="headersearchbutton">
          <FontAwesomeIcon icon={faSearch} className="fa-2x iconhover" />
        </button>
      </div>
    </header>
  );
}

export default Header;
