import React, { useState, useContext } from "react";
import "../styles/navigation.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, Link } from "react-router-dom";

import { CartContext } from "./Context";
function Navigation() {
  const [navOpen, setNavOpen] = useState(false);
  const handletoggle = () => {
    setNavOpen(!navOpen);
  };
  const carts = useContext(CartContext);
  const total = () => {
    return carts[0].reduce((sum, { quantity }) => sum + quantity, 0);
  };
  const renderClasses = () => {
    let cleasses = "navigation";
    if (navOpen) {
      cleasses += " active";
    }
    return cleasses;
  };

  return (
    <>
      <div className="humbermenu">
        <Link to="/carts">
          <div className="shoppingcart">
            <div className="cartquantities">{total()}</div>
            <FontAwesomeIcon className="fa-1x" icon={faShoppingCart} />
          </div>
        </Link>

        <div className="humbermenuicon">
          <FontAwesomeIcon
            onClick={handletoggle}
            className="fa-2x"
            icon={navOpen ? faTimes : faBars}
          />
        </div>
      </div>
      <div onClick={handletoggle} className={renderClasses()}>
        <NavLink exact to="/" activeClassName="selected">
          <div>Home</div>
        </NavLink>

        {/* <NavLink to="/meat" activeClassName="selected">
          <div>Meat Department</div>
        </NavLink> */}
        <NavLink to="/bakery" activeClassName="selected">
          <div>Bakery</div>
        </NavLink>
        <NavLink to="/tea" activeClassName="selected">
          <div>Tea & Sweet</div>
        </NavLink>
        <NavLink to="/driedfruit" activeClassName="selected">
          <div>Dried Fruit</div>
        </NavLink>
        <NavLink to="/saffron" activeClassName="selected">
          <div>Saffron</div>
        </NavLink>
        <NavLink to="/Nuts" activeClassName="selected">
          <div>Nuts</div>
        </NavLink>
        <NavLink to="/grocery" activeClassName="selected">
          <div>Grocery</div>
        </NavLink>
        <NavLink to="/kitchenware" activeClassName="selected">
          <div>Kitchenware</div>
        </NavLink>
        <NavLink to="/contact" activeClassName="selected">
          <div>Contact Us</div>
        </NavLink>
        <NavLink
          to="/carts"
          className="bigscreencart"
          activeClassName="selected"
        >
          <div className="bigscreenshoppingcart">
            <div className="bgscreencartquantities">{total()}</div>
            <FontAwesomeIcon className="fa-2x" icon={faShoppingCart} />
          </div>
        </NavLink>
      </div>
    </>
  );
}

export default Navigation;
