import Nuts from "../assets/nuts/Walnut-Chandler-Light-Halves.jpg";
import Saffron from "../assets/saffron/Saffron-Rock-Candy.jpg";
import Dried from "../assets/driedfruit/anjeer_fig_fig(anjeer).jpg";
import Tea from "../assets/tea/Super-Ceylon-cardamom-Gold.jpg";
import Bakery from "../assets/bakery/nan.jpeg";
import Grocery from "../assets/fargo/Noorani-Rice.jpg";

export const foodservicesimage = [
  { id: 1, text: "Nuts", image: Nuts },
  { id: 2, text: "Saffron", image: Saffron },
  { id: 3, text: "Dried Fruit", image: Dried },
  { id: 4, text: "Tea", image: Tea },
  { id: 5, text: "Bakery", image: Bakery },
  { id: 6, text: "Grocery", image: Grocery },
];
