import { withRouter, Redirect } from "react-router";
import { Authcontext } from "./Authcontext";
import React, { useContext, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../styles/toast.css";
import fire from "../firebase.config";
import "../styles/loginpage.css";
function Loginpage({ history }) {
  const loginsend = useCallback(
    async (event) => {
      event.preventDefault();
      const { username, password } = event.target.elements;
      try {
        fire
          .auth()
          .signInWithEmailAndPassword(username.value, password.value)
          .catch(function (error) {
            toast.info(error.message);
          });

        history.push("/adminpanel");
      } catch (err) {
        console.log(err);
        toast.info("Sign in unsuccessful");
      }
    },
    [history]
  );
  const { currentUser } = useContext(Authcontext);
  if (currentUser) {
    return <Redirect to="/adminpanel" />;
  }
  return (
    <>
      <div className="logincontainer">
        <form className="loginform" onSubmit={loginsend}>
          <label htmlFor="username">User Name:</label>
          <input type="text" name="username" />
          <label htmlFor="password">Password</label>
          <input type="password" name="password" />
          <button type="submit">Login</button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}

export default withRouter(Loginpage);
