import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyBIo_nvVar6D_Kwa1zr_n3qNMPe3MATktc",
    authDomain: "kamiyabsupermarket-fa491.firebaseapp.com",
    databaseURL: "https://kamiyabsupermarket-fa491-default-rtdb.firebaseio.com",
    projectId: "kamiyabsupermarket-fa491",
    storageBucket: "kamiyabsupermarket-fa491.appspot.com",
    messagingSenderId: "937018780338",
    appId: "1:937018780338:web:5d383af63467856e5737ab"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase