import "./styles/App.css";
import "./styles/toast.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navigtion from "./components/Navigation";
import Contactus from "./components/contactus";
import Grocery from "./components/Grocery";
// import Meat from "./components/Meat";
import KitWare from "./components/Kitchenware";
import Bakery from "./components/Bakery";
import Tea from "./components/Tea";
import DriedFruit from "./components/DriedFruit";
import Saffron from "./components/saffron";
import Nuts from "./components/Nuts";
import Adminpanel from "./components/Adminpanel";
import Home from "./components/Home";
import Carts from "./components/Carts";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Privateroute from "./components/Privateroute";
import { CartContextProvider } from "./components/Context";
import { SearchContextProvider } from "./components/SearchContext";
import Login from "./components/Loginpage";
function App() {
  return (
    <>
      <CartContextProvider>
        <SearchContextProvider>
          <Header />
          <Navigtion />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contactus} />
            <Route exact path="/grocery" component={Grocery} />
            {/* <Route exact path="/meat" component={Meat} /> */}
            <Route exact path="/bakery" component={Bakery} />
            <Route exact path="/tea" component={Tea} />
            <Route exact path="/driedfruit" component={DriedFruit} />
            <Route exact path="/saffron" component={Saffron} />
            <Route exact path="/nuts" component={Nuts} />
            <Route exact path="/kitchenware" component={KitWare} />
            <Privateroute exact path="/adminpanel" component={Adminpanel} />
            <Route exact path="/carts" component={Carts} />
            <Route exact path="/login" component={Login} />
          </Switch>
          <Footer />
        </SearchContextProvider>
      </CartContextProvider>
    </>
  );
}

export default App;
