import redFeenikThermos from "../assets/fargo/red-feenik-thermos.jpeg";
import coffeSetGold from "../assets/fargo/coffe-set-two-people-gold.jpeg";
import coffesetTwoPerson from "../assets/fargo/coffe-set-two-people.jpeg";
import blueVaccum from "../assets/fargo/blue-vaccum.jpeg";
import coffeSetSilver from "../assets/fargo/coffe-set-two-poeple-silver.jpeg";
import feenikThermos from "../assets/fargo/feenik-thermos.jpeg";
import glassJug from "../assets/fargo/glass-jug2.jpeg";
import jugGlass from "../assets/fargo/glass-jug.jpeg";
import glassTeapot from "../assets/fargo/glass-tea-pot.jpeg";
import glassWhiteTeaPot from "../assets/fargo/glass-white-tea-pot.jpeg";
import goldStainlesssteelThermos from "../assets/fargo/gold-stainless-thermos.jpeg";
import silverColorTeapot from "../assets/fargo/silver-color-teapot.jpeg";
import silverTeapot from "../assets/fargo/Silver-Tea-pot.jpeg";
import StainlessSteelKettleTeapot from "../assets/fargo/stainless-steel-kettle-teapot.jpeg";
import stainlessThermos from "../assets/fargo/stainless-thermos.jpeg";
import stainlessSteelWaterBoiler from "../assets/fargo/steinless-steel-water-boiler.jpeg";
import teapotWithTeaInfuser from "../assets/fargo/Teapot-with-Tea-Infuser.jpeg";
import BlueThermos from "../assets/fargo/thermos-blue.jpeg";
import vaccumeFlaskThermos from "../assets/fargo/vacum-flask-thermos.jpeg";
import vacum from "../assets/fargo/vacum.jpeg";
import waterBoilerTeapot from "../assets/fargo/water-boiler-teapot.jpeg";
import whistlingTeaKettleStainlessTeapot from "../assets/fargo/Whistling-Tea-Kettle-Stainless-Steel-Teapot.jpeg";
import whiteFeenikThermos from "../assets/fargo/white-feenik-thermos.jpeg";
import whiteThermos from "../assets/fargo/white-thermos.jpeg";

export const tools = [
  { id: 102, name: "Blue Vaccum", image: blueVaccum, price: 7.99 },
  { id: 125, name: "Coffee Set", image: coffeSetGold, price: 24.99 },
  { id: 126, name: "Coffee Set", image: coffesetTwoPerson, price: 24.99 },
  { id: 127, name: "Coffee Set", image: coffeSetSilver, price: 24.99 },
  { id: 140, name: "Feenik Thermos", image: feenikThermos, price: 29.99 },
  { id: 146, name: "Glass Jug", image: glassJug, price: 7.99 },
  { id: 147, name: "Glass Jug", image: jugGlass, price: 7.99 },
  { id: 148, name: "Glass Tea Pot", image: glassTeapot, price: 7.99 },
  { id: 149, name: "Glass Tea Pot", image: glassWhiteTeaPot, price: 7.99 },
  {
    id: 151,
    name: "Gold Stainless Steel Thermos",
    image: goldStainlesssteelThermos,
    price: 29.99,
  },
  {
    id: 193,
    name: "Red Feenik Thermos",
    image: redFeenikThermos,
    price: 29.99,
  },
  {
    id: 212,
    name: "Silver Color Teapot",
    image: silverColorTeapot,
    price: 7.99,
  },
  { id: 213, name: "Silver Teapot", image: silverTeapot, price: 7.99 },
  {
    id: 217,
    name: "Stainless Steel Kettle Teapot",
    image: StainlessSteelKettleTeapot,
    price: 7.99,
  },
  {
    id: 219,
    name: "Stainless Steel Thermos",
    image: stainlessThermos,
    price: 24.99,
  },
  {
    id: 220,
    name: "Stainless Steel Water Boiler",
    image: stainlessSteelWaterBoiler,
    price: 7.99,
  },
  {
    id: 226,
    name: "Teapot With Tea Infuser",
    image: teapotWithTeaInfuser,
    price: 7.99,
  },
  { id: 227, name: "Thermos", image: BlueThermos, price: 19.99 },
  {
    id: 232,
    name: "VaccumeFlaskThermos",
    image: vaccumeFlaskThermos,
    price: 29.99,
  },
  { id: 233, name: "Vaccum", image: vacum, price: 7.99 },
  {
    id: 237,
    name: "Water Boiler Tea Pot",
    image: waterBoilerTeapot,
    price: 7.99,
  },
  {
    id: 239,
    name: "Whistling Tea Kettle",
    image: whistlingTeaKettleStainlessTeapot,
    price: 14.99,
  },
  {
    id: 240,
    name: "White Feenik Thermos",
    image: whiteFeenikThermos,
    price: 29.99,
  },
  { id: 242, name: "White Thermos", image: whiteThermos, price: 24.99 },
];
