//import saffronRedGold from "../assets/fargo/saffron-red-gold.jpeg";
// import stainlessTeapot from "../assets/fargo/stainless-teapot.jpeg";
// import apricotKernel from "../assets/fargo/apricot-kernel.jpeg";
// import almondcandy from "../assets/fargo/almond-candy.jpeg";
// import roseFlower from "../assets/fargo/rose-flower.jpeg";
// import shahSaffron from "../assets/fargo/shah-saffron.jpeg";
// import spice from "../assets/fargo/spice.jpeg";

import avacodaOil from "../assets/fargo/avacoda-oil.jpeg";
import bakingPowder from "../assets/fargo/baking-powder.jpeg";
import bandariPickledVegetables from "../assets/fargo/bandari-pickled-vegetables.jpeg";
import apricotJam from "../assets/fargo/apricot-jam.jpeg";
import basmatiRice from "../assets/fargo/basmate-rice.jpeg";
import blackEyeBeans from "../assets/fargo/black-eye-beans.jpeg";
import blackPepper from "../assets/fargo/black-pepper.jpeg";
import blackPepperPowder from "../assets/fargo/black-pepper-powder.jpeg";
import breadCrumbs from "../assets/fargo/bread-crumbs.jpeg";
import cannedApricot from "../assets/fargo/canned-apricot.jpeg";
import cannedChickPea from "../assets/fargo/canned-chick-pea.jpeg";
import cannedFriedVegetables from "../assets/fargo/canned-fried-vegtables.jpeg";
import cannedLentil from "../assets/fargo/canned-lentil.jpeg";
import cannedSaltCucumber from "../assets/fargo/canned-salt-cucumber.jpeg";
import cannedSourCherry from "../assets/fargo/canned-sour-cherry.jpeg";
import cedrateJam from "../assets/fargo/cedrate-jam.jpeg";
import chickPeaFlour from "../assets/fargo/chick-pea-flour.jpeg";
import chilliePowder from "../assets/fargo/chilli-powder.jpeg";
import chillieSauce from "../assets/fargo/chilli-sauce.jpeg";
import cinnamon from "../assets/fargo/cinnamon.jpeg";
import cinnamonPowder from "../assets/fargo/cinnamon-powder.jpeg";
import cloverhoney from "../assets/fargo/clover-honey.jpeg";
import cocamulticereals from "../assets/fargo/coca-multi-cereals.jpeg";
import cornStarch from "../assets/fargo/corn-starch.jpeg";
import CreamProduct from "../assets/fargo/cream-product.jpeg";
import creamyBasmatiRice from "../assets/fargo/creamy-basmati-rice.jpeg";
import darbariRice from "../assets/fargo/darbari-rice.jpeg";
import dillAneth from "../assets/fargo/dill-aneth.jpeg";
import distilledCardamomWater from "../assets/fargo/distilled-cardamom-water.jpeg";
import distilledChicoryLeafWater from "../assets/fargo/distilled-chicory-leaf-water.jpeg";
import driedHerbs from "../assets/fargo/dried-herbs.jpeg";
import driedVegetables from "../assets/fargo/dried-vegtables.jpeg";
import xtralngSelaBasmatiRice from "../assets/fargo/extra-long-sela-basmati-rice.jpeg";
import figJam from "../assets/fargo/fig-jam.jpeg";
import FriedChickenMix from "../assets/fargo/friend-chicken-mix.jpeg";
import Turmeric from "../assets/fargo/furmeric.jpeg";
import getrockneteLimonen from "../assets/fargo/getrocknete-limonen.jpeg";
import ginger from "../assets/fargo/ginger.jpeg";
import GoldenBasmatiRice from "../assets/fargo/golden-basmati-rice.jpeg";
import haricotBeans from "../assets/fargo/haricot-beans.jpeg";
import hotPepperCrushed from "../assets/fargo/hot-pepper-crushed.jpeg";
import jellyPowder from "../assets/fargo/jelly-powder.jpeg";
import lrgOatFlakes from "../assets/fargo/large-oat-flakes.jpeg";
import lemonPowder from "../assets/fargo/lemon-powder.jpeg";
import limeAndGingerHerbs from "../assets/fargo/lime-and-ginger-herbs.jpeg";
import limeJuice from "../assets/fargo/lime-juice.jpeg";
import marjan from "../assets/fargo/marjan.jpeg";
import marshmallowStrawberry from "../assets/fargo/marshmallow-strawberry.jpeg";
import marshmallowVanilla from "../assets/fargo/marshmallow-vanilla.jpeg";
import milkpak from "../assets/fargo/milk-pak.jpeg";
import mincedPickledVeget from "../assets/fargo/minced-pickled-vegetables.jpeg";
import mintWater from "../assets/fargo/mint-water.jpeg";
import nane from "../assets/fargo/nane.jpeg";
import ncPasta from "../assets/fargo/nc-pasta.jpeg";
import NooraniRice from "../assets/fargo/Noorani-Rice.jpg";
import OrangeBlossomWater from "../assets/fargo/orange-blossom-water.jpeg";
import orangeJam from "../assets/fargo/orange-jam.jpeg";
import parsley from "../assets/fargo/parsley.jpeg";
import pickledJalapenoPepper from "../assets/fargo/pickled-jalapeno-pepper.jpeg";
import pickledPeppers from "../assets/fargo/pickled-peppers.jpeg";
import pickledVeget from "../assets/fargo/pickled-vegetables.jpeg";
import plantagoSeed from "../assets/fargo/plantago-seed.jpeg";
import pomegranatePaste from "../assets/fargo/pomegrante-paste.jpeg";
import pomegranateMolasses from "../assets/fargo/pomogranate-molasses.jpeg";
import popCorn from "../assets/fargo/pop-corn.jpeg";
import premiumQualityRice from "../assets/fargo/premium-quality-rice.jpeg";
import premiumTaromRice from "../assets/fargo/premium-tarom-rice.jpeg";
import PumpkinSeedHalfSalt from "../assets/fargo/pumpkin-seed-half-salt.jpeg";
import qoort from "../assets/fargo/qoorot.jpeg";
import raspberryJam from "../assets/fargo/raspberry-jam.jpeg";
import redKidneyBeans from "../assets/fargo/red-kidney-beans.jpeg";
import redRoseRice from "../assets/fargo/red-rose-rice.jpeg";
import riceheratBakhti from "../assets/fargo/rice-degree-one-herat-bakhti.jpeg";
import RiceWoroji from "../assets/fargo/rice-degree-one-woroji.jpeg";
import roseflowerJam from "../assets/fargo/rose-flower-jam.jpeg";
import sadafTomatoPaste from "../assets/fargo/sadaf-tomato-paste.jpeg";
import saffronSelaRice from "../assets/fargo/saffron-sela-rice.jpeg";
import cucumberPickledVeget from "../assets/fargo/selected-vegetables-pickled-cucumbers.jpeg";
import SeraFigJam from "../assets/fargo/sera-fig-jam.jpeg";
import sesameNaturalOil from "../assets/fargo/sesame-natural-oil.jpeg";
import sesameSeed from "../assets/fargo/sesame-seed.jpeg";
import shishTwookMix from "../assets/fargo/shish-twook-mix.jpeg";
import spicegulla from "../assets/fargo/spice-gulla.jpeg";
import strawberryJam from "../assets/fargo/strawberry-jams.jpeg";
import sumac from "../assets/fargo/sumac.jpeg";
import superGoldenBasmatiRice from "../assets/fargo/super-golden-basmati-rice.jpeg";
import tableSalt from "../assets/fargo/table-salt.jpeg";
import tomatoPaste from "../assets/fargo/tomato-paste.jpeg";
import Turmerics from "../assets/fargo/turmeric.jpeg";
import unripeGrapeJuice from "../assets/fargo/unripe-grape-juice.jpeg";
import vegetGhee from "../assets/fargo/vegetabe-ghee.jpeg";
import vimtoFruitCordial from "../assets/fargo/vimto-fruit-cordial.jpeg";
import virginOliveOil from "../assets/fargo/virgin-olive-oil.jpeg";
import whiteKidneyBeans from "../assets/fargo/white-kidney-beans.jpeg";
import wholeWheat from "../assets/fargo/whole-wheat.jpeg";
import zamzamDate from "../assets/fargo/zamzam-date.jpeg";

export const groceries = [
  // { id: 87, name: "Almond Candy", image: almondcandy, price: 2.99 },

  { id: 89, name: "Apricot Jam", image: apricotJam, price: 3.99 },
  // { id: 90, name: "Apricot Kernel", image: apricotKernel, price: 2.99 },
  { id: 91, name: "Avacoda Oil", image: avacodaOil, price: 11.99 },
  { id: 92, name: "Baking Powder", image: bakingPowder, price: 4.99 },
  {
    id: 93,
    name: "Bandari Pickled Vegetables",
    image: bandariPickledVegetables,
    price: 3.99,
  },
  {
    id: 94,
    name: "Basmati Rice",
    image: basmatiRice,
    price: 12.99,
    unit: "10LB",
  },
  {
    id: 94.1,
    name: "Basmati Rice",
    image: basmatiRice,
    price: 47.99,
    unit: "40LB",
  },
  { id: 98, name: "Black Eye Beans", image: blackEyeBeans, price: 4.99 },
  { id: 99, name: "Black Pepper", image: blackPepper, price: 3.99 },
  {
    id: 101,
    name: "Black Pepper Powder",
    image: blackPepperPowder,
    price: 3.99,
  },

  { id: 103, name: "Bread Crumbs", image: breadCrumbs, price: 2.99 },
  { id: 104, name: "Canned Apricot", image: cannedApricot, price: 2.99 },
  { id: 105, name: "Canned Chick Pea", image: cannedChickPea, price: 1.99 },
  {
    id: 106,
    name: "Canned Fried Vegetables",
    image: cannedFriedVegetables,
    price: 3.99,
    size: "Small",
  },
  {
    id: 106.2,
    name: "Canned Fried Vegetables",
    image: cannedFriedVegetables,
    price: 5.99,
    size: "Large",
  },
  { id: 107, name: "Canned Lentil", image: cannedLentil, price: 1.99 },
  { id: 108, name: "Cedrate Jam", image: cedrateJam, price: 3.99 },
  {
    id: 109,
    name: "Canned Source Cherry",
    image: cannedSourCherry,
    price: 2.99,
  },
  {
    id: 110,
    name: "Canned Salt Cucumber",
    image: cannedSaltCucumber,
    price: 3.99,
  },

  { id: 114, name: "Chick Pea Flour", image: chickPeaFlour, price: 2.99 },
  { id: 115, name: "Chilie Powder", image: chilliePowder, price: 2.99 },
  { id: 116, name: "Chillie Sauce", image: chillieSauce, price: 3.99 },

  { id: 119, name: "Cinnamon", image: cinnamon, price: 2.99 },
  { id: 120, name: "CinnamonPowder", image: cinnamonPowder, price: 2.99 },
  {
    id: 121,
    name: "Clover Honey",
    image: cloverhoney,
    price: 5.99,
    size: "Small",
  },
  {
    id: 121.2,
    name: "Clover Honey",
    image: cloverhoney,
    price: 9.99,
    size: "Large",
  },

  { id: 123, name: "Coca Multi Cereals", image: cocamulticereals, price: 2.99 },

  { id: 128, name: "Corn Strach", image: cornStarch, price: 2.99 },
  { id: 129, name: "Cream Product", image: CreamProduct, price: 2.99 },
  {
    id: 130,
    name: "Creamy Basmati Rice",
    image: creamyBasmatiRice,
    price: 12.99,
  },

  { id: 133, name: "Darbari Rice", image: darbariRice, price: 12.99 },
  { id: 134, name: "Dill (Aneth)", image: dillAneth, price: 3.99 },
  {
    id: 135,
    name: "Distilled Cardamom Water",
    image: distilledCardamomWater,
    price: 3.99,
  },
  {
    id: 136,
    name: "Distilled Chicory Leaf Water",
    image: distilledChicoryLeafWater,
    price: 3.99,
  },
  {
    id: 137,
    name: "Dried Herbs",
    image: driedHerbs,
    price: 3.99,
  },
  { id: 138, name: "Dried Vegetables", image: driedVegetables, price: 3.99 },
  {
    id: 139,
    name: "Extra Long Sela Basmati Rice",
    image: xtralngSelaBasmatiRice,
    price: 12.99,
    size: "Small",
  },
  {
    id: 139.2,
    name: "Extra Long Sela Basmati Rice",
    image: xtralngSelaBasmatiRice,
    price: 47,
    size: "Large",
  },

  { id: 141, name: "Fig Jam", image: figJam, price: 3.99 },

  { id: 142, name: "Fried Chicken Mix", image: FriedChickenMix, price: 2.99 },
  { id: 143, name: "Turmeric", image: Turmeric, price: 3.99 },
  {
    id: 144,
    name: "Getrockenete Limonen",
    image: getrockneteLimonen,
    price: 7.99,
  },
  { id: 145, name: "Ginger", image: ginger, price: 4.99 },

  {
    id: 150,
    name: "Golden Basmati Rice",
    image: GoldenBasmatiRice,
    price: 12.99,
  },

  //   { id: 153, name: "Green Tea With Mint", image: greenTeaWithMint, price: 2.99 },

  { id: 155, name: "Haricot Beans", image: haricotBeans, price: 2.99 },

  {
    id: 159,
    name: "HotPepper Crushed",
    image: hotPepperCrushed,
    price: 4.99,
  },
  { id: 160, name: "Jelly Powder", image: jellyPowder, price: 1.99 },
  { id: 161, name: "Large Oat Flakes", image: lrgOatFlakes, price: 2.99 },
  { id: 162, name: "Lemon Powder", image: lemonPowder, price: 3.99 },
  {
    id: 163,
    name: "Lime And Ginger Herbs",
    image: limeAndGingerHerbs,
    price: 2.99,
  },
  { id: 164, name: "Lime Juice", image: limeJuice, price: 4.99 },
  { id: 165, name: "Marjan", image: marjan, price: 11.99, size: "Medium" },
  {
    id: 165.2,
    name: "Marjan",
    image: marjan,
    price: 5.99,
    size: "Small",
  },
  {
    id: 166,
    name: "Marshmallow Strawberry",
    image: marshmallowStrawberry,
    price: 2.99,
  },
  {
    id: 167,
    name: "Marshmallow Vanilla",
    image: marshmallowVanilla,
    price: 2.99,
  },

  { id: 169, name: "Milk Pak", image: milkpak, price: 6.99 },
  {
    id: 170,
    name: "Minced Pickled Vegetables",
    image: mincedPickledVeget,
    price: 3.99,
  },
  { id: 171, name: "Mint Water", image: mintWater, price: 3.99 },

  { id: 174, name: "Nane", image: nane, price: 2.99 },
  { id: 175, name: "NC Pasta", image: ncPasta, price: 1.99 },
  {
    id: 176,
    name: "Noorani Rice",
    image: NooraniRice,
    price: 47,
  },

  {
    id: 178,
    name: "Orange Blossom Water",
    image: OrangeBlossomWater,
    price: 1.99,
  },
  { id: 179, name: "Orange Jam", image: orangeJam, price: 3.99 },
  { id: 180, name: "Parsley", image: parsley, price: 3.99 },
  {
    id: 181,
    name: "Pickled Jalapeno Pepper",
    image: pickledJalapenoPepper,
    price: 4.99,
  },
  { id: 182, name: "Pickled Peppers", image: pickledPeppers, price: 4.99 },
  { id: 183, name: "Pickled Vegetables", image: pickledVeget, price: 4.99 },
  { id: 184, name: "Plantago Seed", image: plantagoSeed, price: 3.99 },
  { id: 185, name: "PomeGranate Paste", image: pomegranatePaste, price: 3.99 },
  {
    id: 186,
    name: "PomeGranate Molasses",
    image: pomegranateMolasses,
    price: 5.99,
  },
  { id: 187, name: "Pop Corn", image: popCorn, price: 3.99 },
  {
    id: 188,
    name: "Premium Quality Rice",
    image: premiumQualityRice,
    price: 12.99,
  },
  {
    id: 189,
    name: "Premium Tarom Rice",
    image: premiumTaromRice,
    price: 24.99,
  },
  {
    id: 190,
    name: "Pumpkin Seed Half Salt",
    image: PumpkinSeedHalfSalt,
    price: 4.99,
  },
  { id: 191, name: "Qoorot", image: qoort, price: 7.99 },
  { id: 192, name: "Raspberry Jam", image: raspberryJam, price: 4.99 },

  { id: 194, name: "Red Kidney Beans", image: redKidneyBeans, price: 1.99 },
  {
    id: 195,
    name: "Red Rose Rice",
    image: redRoseRice,
    price: 12.99,
    size: "Medium",
  },
  {
    id: 195.2,
    name: "Red Rose Rice",
    image: redRoseRice,
    price: 47,
    size: "Large",
  },
  {
    id: 196,
    name: "Rice Heart Bakhti",
    image: riceheratBakhti,
    price: 13.99,
    size: "Medium",
  },
  {
    id: 196.2,
    name: "Rice Heart Bakhti",
    image: riceheratBakhti,
    price: 47.99,
    size: "Large",
  },

  {
    id: 197,
    name: "Rice Woroji",
    image: RiceWoroji,
    price: 47,
  },

  { id: 199, name: "Rose Flower Jam", image: roseflowerJam, price: 3.99 },
  // { id: 201, name: "Rose Flower", image: roseFlower, price: 2.99 },
  { id: 202, name: "Sadaf Tamato Paste", image: sadafTomatoPaste, price: 6.99 },
  // { id: 203, name: "Saffron Red Gold", image: saffronRedGold, price: 2.99 },
  { id: 204, name: "Saffron Sela Rice", image: saffronSelaRice, price: 12.99 },

  {
    id: 206,
    name: "Pickled Vegetable Cucumber",
    image: cucumberPickledVeget,
    price: 3.99,
  },
  { id: 207, name: "Sera Fig Jam", image: SeraFigJam, price: 3.99 },
  {
    id: 208,
    name: "Sesame Natural Oil",
    image: sesameNaturalOil,
    price: 49.99,
  },
  { id: 209, name: "Sesame Seed", image: sesameSeed, price: 2.99 },
  // { id: 210, name: "Shah Saffron", image: shahSaffron, price: 2.99 },
  { id: 211, name: "Shish Twook Mix", image: shishTwookMix, price: 2.99 },

  { id: 215, name: "Spice", image: spicegulla, price: 2.99 },
  // { id: 216, name: "Spice", image: spice, price: 7.99 },

  // {
  //   id: 218,
  //   name: "Stainless Steel Teapot",
  //   image: stainlessTeapot,
  //   price: 2.99,
  // },

  { id: 221, name: "Strawberry Jam", image: strawberryJam, price: 3.99 },

  { id: 223, name: "Sumac", image: sumac, price: 4.99 },
  {
    id: 246,
    name: "Super Golden Basmati Rice",
    image: superGoldenBasmatiRice,
    price: 12.99,
  },

  { id: 225, name: "Table Salt", image: tableSalt, price: 2.99 },

  { id: 229, name: "Tomato Paste", image: tomatoPaste, price: 4.99 },
  { id: 230, name: "Turmeric", image: Turmerics, price: 3.99 },
  { id: 231, name: "UnripeGrapeJuice", image: unripeGrapeJuice, price: 4.99 },

  { id: 234, name: "Vegetable Ghee", image: vegetGhee, price: 5.99 },
  {
    id: 235,
    name: "Vimto Fruit Cordial",
    image: vimtoFruitCordial,
    price: 3.99,
  },
  { id: 236, name: "Virgin Olive Oil", image: virginOliveOil, price: 7.99 },

  {
    id: 241,
    name: "White Kidney Beans",
    image: whiteKidneyBeans,
    price: 4.99,
  },

  { id: 244, name: "Whole wheat", image: wholeWheat, price: 3.99 },
  { id: 245, name: "Zamzam Date", image: zamzamDate, price: 11.99 },
];
