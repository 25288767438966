import React, { useState } from "react";

export const SearchContext = React.createContext();

export const SearchContextProvider = (props) => {
  const [query, setQuery] = useState([])
  return (
    <SearchContext.Provider value={[query,setQuery]}>
      {props.children}
    </SearchContext.Provider>
  );
};
