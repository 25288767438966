// import jelabi from "../assets/bakery/jelabi.jpeg";
// import cookies from "../assets/bakery/cookies.jpeg";
// import afghaniroot from "../assets/bakery/routs.jpeg";
//import goldenrisins from "../assets/driedfruit/Golden-raisins.jpg";
//import SpecialBlendTea from "../assets/tea/special-blend-tea.jpg";
//import snowwhite from "../assets/nuts/Snow-White-Pumpkin-Seeds.png";
//import macadamiaNuts from "../assets/nuts/Macadamia-Nuts.png";
//import mashaadichickpeas from "../assets/nuts/Mashaadi-Chickpeas.png";
//import papayachunks from "../assets/driedfruit/Papaya-Chunks.jpg";
//import pineappleRings from "../assets/driedfruit/Pineapple-Rings.jpg";
//import PrunejumboPitted from "../assets/driedfruit/Prunes-Jumbo-Pitted.png";
//import gridaakRaisins from "../assets/driedfruit/Girdaak-Raisins.png";
//import cranberries from "../assets/driedfruit/Cranberries-Ocean-Spray.png";
//import crystallizedginner from "../assets/driedfruit/Crystallized-Ginger-Slices.png";
//import kiwiSlices from "../assets/driedfruit/Kiwi-Slices.png";
//import kashmar from "../assets/driedfruit/Kashmar-Green-Raisins.png";
//import MangoSlices from "../assets/driedfruit/Mango-Slices.png";
//import rawblanched from "../assets/nuts/Raw-Blanched-Hazelnuts.png";
//import silveredalmonds from "../assets/nuts/Silvered-Almonds.jpg";
import aniseherbs from "../assets/fargo/anise-herbs.jpeg";
import biscuitfilledwithhazelnuts from "../assets/fargo/biscuit-filled-with-hazel-nuts.jpeg";
import biscuitsChocolateSandwich from "../assets/fargo/biscuits-chocolate-sandwich.jpeg";
import centerFilledFruitChew from "../assets/fargo/center-filled-fruit-chew.jpeg";
import greenTeaWithMint from "../assets/fargo/green-tea-with-mint.jpeg";
import hardCandy from "../assets/fargo/hard-candy-with-caramel-flavored-milk-filling.jpeg";
import biscuitHazelNutsfilled from "../assets/fargo/hazelnuts-cream-filled-crunchy-sandwich-biscuit.jpeg";
import heratSaffron from "../assets/fargo/herat-saffron.jpeg";
import mongoportakal from "../assets/fargo/mongo-portakal.jpeg";
import oatBarleyandMultiCerealsBiscuits from "../assets/fargo/oat-barley-and-multi-cereals-biscuits.jpeg";
import richCinnamonHerbs from "../assets/fargo/rich-cinnamon-herbs.jpeg";
import sndwchBiscuitwithMarshmallowCaramelSauce from "../assets/fargo/sandwich-biscuits-with-marshmallow-caramel-sauce.jpeg";
import sugarCubes from "../assets/fargo/sugar-cubes.jpeg";
import sweets from "../assets/fargo/sweets.jpeg";
import thymeHerbs from "../assets/fargo/thyme-herbs.jpeg";
import wheatBiscuit from "../assets/fargo/wheat-biscuit.jpeg";
import wholeWheatBiscuit from "../assets/fargo/whole-wheat-biscuit.jpeg";
import softAlmondFudgeShirpera from "../assets/fargo/soft-almond-fudge-shirpera.jpeg";
import mulberryNectarBiscuits from "../assets/fargo/mulbery-nectar-biscuits.jpeg";
import mazafatiDate from "../assets/fargo/mazafati-date.jpeg";
import hibiscusHerbs from "../assets/fargo/hibiscus-herbs.jpeg";
import chocolate from "../assets/fargo/chocolate.jpeg";
import chocolateMilkEggs from "../assets/fargo/chocolate-milk-eggs.jpeg";
import cocaMulberryNectarBiscuit from "../assets/fargo/coca-mulberry-nectar-biscuit.jpeg";
import cocaSandwichBiscuitwithVanillaSauce from "../assets/fargo/coca-sandwich-biscuit-with-vanilla-sauce.jpeg";
import CrispySimyanColored from "../assets/fargo/crispy-simyan-colored.jpeg";
import CrispySimyan from "../assets/fargo/crispy-simyan.jpeg";
import chamomileAndMint from "../assets/fargo/chamomile-and-mint.jpeg";
import chamomileHerbs from "../assets/fargo/chamomile-herbs.jpeg";
import biscuits from "../assets/fargo/biscuits.jpeg";
import afghanjelabi from "../assets/bakery/afghani-jelabi.jpeg";
import baghlawa from "../assets/bakery/baghlawa.jpeg";
import afghanibread from "../assets/bakery/nan.jpeg";
import breadnan from "../assets/bakery/bread-nan.jpeg";
import cookie from "../assets/bakery/cookie.jpeg";
import leekPolani from "../assets/bakery/leek-polani.jpeg";
import kolcha from "../assets/bakery/kolcha.jpeg";
import nan from "../assets/bakery/nan.jpeg";
import raisinandzestcarrot from "../assets/bakery/palwa-ingredients.jpeg";
import potatoPolani from "../assets/bakery/potato-polani.jpeg";
import pumpkinPolani from "../assets/bakery/pumpkin-polani.jpeg";
import root from "../assets/bakery/Root-afghani-food.jpeg";
import sambosa from "../assets/bakery/sambosa.jpeg";
import turshi from "../assets/bakery/turshi.jpeg";
import greeTeabags100sachets from "../assets/tea/Nazo-Green-Teabags-100-Sachets.jpg";
import greenTeaBoldandSmooth from "../assets/tea/Nazo-Green-Tea-Bold-and-Smooth.png";
import GreenTeaLightandRefreshing from "../assets/tea/Nazo-Green-Tea-Light-and-Refreshing.png";
import GreenTeaTraditionalTaste from "../assets/tea/Nazo-Green-Tea-Traditional-Taste.png";
import PureCeylongTea from "../assets/tea/Pure-Ceylon-Tea.jpg";
import SpecialBlenTea50bags from "../assets/tea/special-blend-tea-50-tea-bags.jpg";
import SpecialBlendTeaearlyGrey from "../assets/tea/special_blend_tea_earl_grey.jpg";
import superCeylonCardamomGold from "../assets/tea/Super-Ceylon-cardamom-Gold.jpg";
import superCeylongEarlGrayTea from "../assets/tea/Super-Ceylon-Earl-Gray-Tea.jpg";
import SuperCyelongGreyTea from "../assets/tea/Super-Cyelon-Earl-Grey-Tea.jpg";
import afghngreenraising from "../assets/driedfruit/Afghan-Green-Raisins.png";
import anjeer from "../assets/driedfruit/anjeer_fig_fig(anjeer).jpg";
import apricodeSlice from "../assets/driedfruit/Apricod-Slices.png";
import barberries from "../assets/driedfruit/Barberries(Zereshk).jpg";
import Mulberries from "../assets/driedfruit/Dried-Black-Mulberries.jpg";
import blackraisins from "../assets/driedfruit/Dried-Black-Raisins.jpg";
import figShirazi from "../assets/driedfruit/Dried-Figs-Shirazi.jpg";
import hunza from "../assets/driedfruit/Dried-Green-Raisins(Hunza).jpg";
import sinjid from "../assets/driedfruit/Dried-Oleastor-Sinjid.jpg";
import redraisins from "../assets/driedfruit/Dried-Red-Raisins.jpg";
import whitemulberries from "../assets/driedfruit/Dried-White-Mulberries.png";
import wholeapricot from "../assets/driedfruit/Dried-Whole-Apricot.jpg";
import aabjosh from "../assets/driedfruit/Golden-Raisins-Aab-Josh.jpg";
import pistachioTurkish from "../assets/driedfruit/Pistachios-Turkish.jpg";

import whiterockcandy from "../assets/driedfruit/White-Rock-Candy.jpg";
import apricotRolls from "../assets/driedfruit/Apricot-Rolls-Chapa-Namak.png";
import goldenplums from "../assets/driedfruit/Golden-Plums-Alo-Bokhara.png";
import jujubi from "../assets/driedfruit/Jujube-Onhab.png";
import LemonPistachios from "../assets/driedfruit/Lemon-Pistachios.png";
import pistachiosAkbari from "../assets/driedfruit/Pistachios-Akbari.png";
import shamsiPistachios from "../assets/driedfruit/Shamsi-Pistachios.png";
import rockcandy from "../assets/driedfruit/White-Rock-Candy-with-Threads.png";
import almond from "../assets/nuts/almond.jpg";
import apricotkernel from "../assets/nuts/Apricot-Kernel.png";
import cashews from "../assets/nuts/Cashews-Raw-Jumbo.jpg";
import roastedcashews from "../assets/nuts/Cashews-Roasted-and-Salted-Jumbo.png";
import unsaltedroasted from "../assets/nuts/Cashews-Roasted-Unsalted.jpg";
import chickpeas from "../assets/nuts/Light-Slat-ChickPeas.png";
import mazarichickpeas from "../assets/nuts/Mazari-Chickpeas.png";
import pecans from "../assets/nuts/Pecans-Fancy-Mammoth-Halves.png";
import pinenuts from "../assets/nuts/Pine-Nuts-Jalgoza.jpg";
import pistachiokernel from "../assets/nuts/Pistachio-Kernel.jpg";
import pumpkinseed from "../assets/nuts/Pumpkins-Seed-Kernels.png";
import silveredpistachios from "../assets/nuts/Silvered-Pistachios.jpg";
import slicedblanched from "../assets/nuts/Sliced-Blanched-Almonds.jpg";
import sugaralmonds from "../assets/nuts/Sugar-Coated-Almonds-Nuqul.jpg";
import sunflowers from "../assets/nuts/Sunflowers-Seeds.jpg";
import walnuts from "../assets/nuts/Walnut-Chandler-Light-Halves.jpg";
import saffron from "../assets/saffron/saffron.jpg";
import saffronrockcandy from "../assets/saffron/Saffron-Rock-Candy.jpg";
import saffronwiththreads from "../assets/saffron/Saffron-Rock-Candy-with-Threads.png";
import nooranirice from "../assets/fargo/Noorani-Rice.jpg";
import nanKhashKhash from '../assets/bakery/nankhashkash.jpeg'
import uzbakinan from '../assets/bakery/nanuzbaki.jpeg'
export const groceries = [
  {
    id: 83,
    name: "Noorani Rice",
    image: nooranirice,
    price: 10,
  },
];
export const saffrons = [
  {
    id: 83,
    name: "Saffron",
    image: saffron,
    price: 2.56,
  },
  {
    id: 157,
    name: "Herat Saffron",
    image: heratSaffron,
    price: 6,
    size: "1 KG",
  },
  {
    id: 157.2,
    name: "Herat Saffron",
    image: heratSaffron,
    price: 12,
    size: "2 KG",
  },
  {
    id: 157.3,
    name: "Herat Saffron",
    image: heratSaffron,
    price: 18,
    size: "2 KG",
  },
];

export const nuts = [
  {
    id: 62,
    name: "Almond",
    image: almond,
    price: 7.99,
  },
  {
    id: 63,
    name: "Apricot Kernel",
    image: apricotkernel,
    price: 7.99,
  },
  {
    id: 64,
    name: "Cashews Raw Jumbo",
    image: cashews,
    price: 9.99,
  },
  {
    id: 65,
    name: "Cashews Roasted & Salted Jumbo",
    image: roastedcashews,
    price: 9.99,
  },
  {
    id: 66,
    name: "Cashews Roasted Unsalted",
    image: unsaltedroasted,
    price: 9.99,
  },
  {
    id: 67,
    name: "Ligh Salt Chickpeas",
    image: chickpeas,
    price: 4.99,
    unit: "LB",
  },
  // {
  //   id: 68,
  //   name: "Macadamia Nuts",
  //   image: macadamiaNuts,
  //   price: 2.99,
  // },
  // {
  //   id: 69,
  //   name: "Mashaadi Chickpeas",
  //   image: mashaadichickpeas,
  //   price: 2.99,
  // },
  {
    id: 70,
    name: "Mazari Chickpeas",
    image: mazarichickpeas,
    price: 4.99,
    unit: "LB",
  },
  {
    id: 71,
    name: "Pecan Fancy Mammoth Halves",
    image: pecans,
    price: 4.99,
    unit: "LB",
  },
  {
    id: 72,
    name: "Pine Nuts (Jalgoza)",
    image: pinenuts,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 73,
    name: "Pistachio Kernels",
    image: pistachiokernel,
    price: 24.99,
    unit: "LB",
  },
  {
    id: 74,
    name: "Pumpkin Seed",
    image: pumpkinseed,
    price: 7.99,
    unit: "200KG",
  },
  // {
  //   id: 75,
  //   name: "Raw Blanched (Hazelnuts)",
  //   image: rawblanched,
  //   price: 2.99,
  // },
  // {
  //   id: 76,
  //   name: "Silvered Almonds",
  //   image: silveredalmonds,
  //   price: 2.99,
  // },
  {
    id: 77,
    name: "Silvered Pistachios",
    image: silveredpistachios,
    price: 4.99,
    unit: "Pack",
  },
  {
    id: 78,
    name: "Sliced Blanched Almonds",
    image: slicedblanched,
    price: 8.99,
    unit: "Pack",
  },
  // {
  //   id: 79,
  //   name: "Snow White Pumpkin Seeds",
  //   image: snowwhite,
  //   price: 2.99,
  // },
  {
    id: 80,
    name: "Sugar Coated Almonds (Nuqul)",
    image: sugaralmonds,
    price: 4.99,
    unit: "Pack",
  },
  {
    id: 81,
    name: "Sunflowers Seeds",
    image: sunflowers,
    price: 7.99,
    size: "Small",
  },
  {
    id: 81.2,
    name: "Sunflowers Seeds",
    image: sunflowers,
    price: 14.99,
    size: "Medium",
  },
  {
    id: 82,
    name: "Walnuts Chandler (Light Halves)",
    image: walnuts,
    price: 7.99,
    unit: "LB",
  },
];

export const driedfruits = [
  {
    id: 30,
    name: "Afghani Green Raisins",
    image: afghngreenraising,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 31,
    name: "Anjeer (Fig)",
    image: anjeer,
    price: 12.99,
    unit: "LB",
  },
  {
    id: 32,
    name: "Apricot Slices",
    image: apricodeSlice,
    price: 7.99,
  },
  {
    id: 33,
    name: "Barberries (Zereshk)",
    image: barberries,
    price: 4.99,
    unit: "Pack",
  },
  {
    id: 34,
    name: "Black Mulberries",
    image: Mulberries,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 35,
    name: "Black Raisins",
    image: blackraisins,
    price: 6.99,
  },
  {
    id: 36,
    name: "Fig Shirazi",
    image: figShirazi,
    price: 13.99,
    unit: "LB",
  },
  {
    id: 37,
    name: "Green Raisins (Hunza)",
    image: hunza,
    price: 4.99,
    unit: "LB",
  },
  {
    id: 38,
    name: "Dried Oleastor (Sinjid)",
    image: sinjid,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 39,
    name: "Red Raisins",
    image: redraisins,
    price: 4.99,
    unit: "LB",
  },
  {
    id: 40,
    name: "White Mulberries",
    image: whitemulberries,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 41,
    name: "Whole Apricot",
    image: wholeapricot,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 42,
    name: "Golden Raisins (Aab Josh)",
    image: aabjosh,
    price: 3.99,
    unit: "LB",
  },
  // {
  //   id: 43,
  //   name: "Golden Raisins",
  //   image: goldenrisins,
  //   price: 2.99,
  // },
  // {
  //   id: 44,
  //   name: "Papaya Chunks",
  //   image: papayachunks,
  //   price: 2.99,
  // },
  // {
  //   id: 45,
  //   name: "Pineapple Rings",
  //   image: pineappleRings,
  //   price: 2.99,
  // },
  {
    id: 46,
    name: "Pistachios Turkish",
    image: pistachioTurkish,
    price: 12.99,
    unit: "LB",
  },
  // {
  //   id: 47,
  //   name: "Prune Jumbo Pitted",
  //   image: PrunejumboPitted,
  //   price: 2.99,
  // },
  {
    id: 48,
    name: "White Rock Candy",
    image: whiterockcandy,
    price: 6.99,
    unit: "Pack",
  },
  // {
  //   id: 49,
  //   name: "Girdaak Raisins",
  //   image: gridaakRaisins,
  //   price: 2.99,
  // },
  {
    id: 50,
    name: "Apricot Rolls (Chapa Namak)",
    image: apricotRolls,
    price: 7.99,
    unit: "LB",
  },
  // {
  //   id: 51,
  //   name: "Cranberries Ocean Spray",
  //   image: cranberries,
  //   price: 2.99,
  // },
  // {
  //   id: 52,
  //   name: "Crystallized Ginger Slices",
  //   image: crystallizedginner,
  //   price: 2.99,
  // },
  {
    id: 53,
    name: "Golden Plums (Alo Bokhara)",
    image: goldenplums,
    price: 7.99,
    unit: "LB",
  },
  {
    id: 54,
    name: "Jujube (Onhab)",
    image: jujubi,
    price: 7.99,
    unit: "LB",
  },
  // {
  //   id: 55,
  //   name: "Kiwi Slices",
  //   image: kiwiSlices,
  //   price: 2.99,
  // },
  // {
  //   id: 56,
  //   name: "Kashmar Green Raisins",
  //   image: kashmar,
  //   price: 2.99,
  // },
  {
    id: 57,
    name: "Lemon Pistachios",
    image: LemonPistachios,
    price: 12.99,
    unit: "LB",
  },
  // {
  //   id: 58,
  //   name: "Mango Slices",
  //   image: MangoSlices,
  //   price: 2.99,
  // },
  {
    id: 59,
    name: "Pistachio Akbari",
    image: pistachiosAkbari,
    price: 12.99,
    unit: "LB",
  },
  {
    id: 60,
    name: "Shamsi Pistachios",
    image: shamsiPistachios,
    price: 12.99,
    unit: "LB",
  },
  {
    id: 61,
    name: "White Rock Canday With Threads",
    image: rockcandy,
    price: 6.99,
    unit: "Pack",
  },
];

export const nans = [
  {
    id: 4,
    name: "Uzbaki Nan",
    image: uzbakinan,
    price: 1.6,
  },
  {
    id: 5,
    name: "White Nan",
    image: breadnan,
    price: 1.6,
  },
  {
    id:7, 
    name: "Nan KhashKhash",
    image: nanKhashKhash,
    price: 1.99

  }
  ,
  {
    id: 10,
    name: "Nan Siya (black Naan)",
    image: nan,
    price: 1.6,
  },
  {
    id: 8,
    name: "Leek Bolani",
    image: leekPolani,
    price: 4.5,
  },
  {
    id: 12,
    name: "Potato Bolani",
    image: potatoPolani,
    price: 4.5,
  },
  {
    id: 13,
    name: "Mix Bolani",
    image: pumpkinPolani,
    price: 4.5,
  },
  {
    id: 14,
    name: "Root",
    image: root,
    price: 4.99,
  },
  {
    id: 6,
    name: "Cookie",
    image: cookie,
    price: 7.99,
    unit: "LB",
  },
  // {
  //   id: 7,
  //   name: "Cookies",
  //   image: cookies,
  //   price: 1,
  // },

  {
    id: 9,
    name: "Kolcha",
    image: kolcha,
    price: 7.99,
    unit: "LB",
  },

  {
    id: 1,
    name: "Afghan Jelabi",
    image: afghanjelabi,
    price: 4.99,
    unit: "LB",
  },
  // {
  //   id: 2,
  //   name: "Jelabi",
  //   image: jelabi,
  //   price: 6.99,
  // },
  {
    id: 3,
    name: "Baghlawa",
    image: baghlawa,
    price: 8.99,
    unit: "LB",
  },
  { id: 95, name: "Biscuits", image: biscuitfilledwithhazelnuts, price: 1.99 },
  { id: 96, name: "Biscuits", image: biscuitsChocolateSandwich, price: 0.99 },
  { id: 97, name: "Biscuits", image: biscuits, price: 3.99 },
  {
    id: 124,
    name: "Coca Sandwich Biscuit",
    image: cocaSandwichBiscuitwithVanillaSauce,
    price: 0.99,
  },
  {
    id: 173,
    name: "Mulberry Nectar Biscuits",
    image: mulberryNectarBiscuits,
    price: 1.99,
  },
  {
    id: 205,
    name: "Sandwich Biscuit Marshmallow Carmel Sauce",
    image: sndwchBiscuitwithMarshmallowCaramelSauce,
    price: 0.99,
  },
  {
    id: 177,
    name: "Oat Barely Multi Cereal Biscuit",
    image: oatBarleyandMultiCerealsBiscuits,
    price: 0.99,
  },
  {
    id: 156,
    name: "HazelNuts Biscuits",
    image: biscuitHazelNutsfilled,
    price: 2.99,
  },
  {
    id: 122,
    name: "Coca Mulberry Nectar Biscuit",
    image: cocaMulberryNectarBiscuit,
    price: 0.99,
  },
  { id: 238, name: "Wheat Biscuit", image: wheatBiscuit, price: 0.99 },
  {
    id: 243,
    name: "Whole Wheat Biscuit",
    image: wholeWheatBiscuit,
    price: 0.99,
  },
  {
    id: 11,
    name: "Carrot Zest & Raisins",
    image: raisinandzestcarrot,
    price: 3.99,
  },

  // {
  //   id: 15,
  //   name: "Afghani Root",
  //   image: afghaniroot,
  //   price: 1,
  // },
  {
    id: 16,
    name: "Sambosa (Daffy) ",
    image: sambosa,
    price: 1.5,
    unit: "each",
  },
  {
    id: 17,
    name: "Turshi (Pickled Vegetables)",
    image: turshi,
    price: 4.99,
  },
];
export const slides = [
  {
    title: "Afghani Dried Fruit",
    description: "Find the Best Afghani Dried Fruit",
    path: shamsiPistachios,
  },
  {
    title: "Best Afghani Bread",
    description: "Find the Best taste",
    path: nan,
  },
  {
    title: "Apricot Slices",
    description: "Apricot Slices",
    path: apricodeSlice,
  },
  {
    title: "Barberries (Zerashk)",
    description: "Find the Best taste",
    path: barberries,
  },
  {
    title: "Leek Bolani",
    description: "Different Flavor of Bolani",
    path: leekPolani,
  },
  {
    title: "Potato Bolani",
    description: "Tasty Potato Bolani",
    path: potatoPolani,
  },
  {
    title: "Mix Bolani",
    description: "Potato with Leek",
    path: pumpkinPolani,
  },
  {
    title: "Uzbaki Nan",
    description: "Variety of Bread",
    path: afghanibread,
  },
  {
    title: "Baghlawa",
    description: "Best Sweet",
    path: baghlawa,
  },
  {
    title: "Dried Oleastor (Sinjid)",
    description: "Special Afghani Dried Fruit",
    path: sinjid,
  },
];

export const teas = [
  {
    id: 18,
    name: "Nazo Green Tea",
    catagory: "Green Tea",
    image: greeTeabags100sachets,
    description: "100 Tea Bags Sachets",
    price: 5.99,
  },
  {
    id: 19,
    name: "Nazo Tea Bold & Smoth",
    catagory: "Green Tea",
    image: greenTeaBoldandSmooth,
    description: "Bold and Smooth",
    price: 3.99,
    size: "Small",
  },
  {
    id: 19.1,
    name: "Nazo Tea Bold & Smoth",
    catagory: "Green Tea",
    image: greenTeaBoldandSmooth,
    description: "Bold and Smooth",
    price: 7.99,
    size: "Medium",
  },
  {
    id: 19.2,
    name: "Nazo Tea Bold & Smoth",
    catagory: "Green Tea",
    image: greenTeaBoldandSmooth,
    description: "Bold and Smooth",
    price: 11.99,
    size: "Large",
  },
  {
    id: 20,
    name: "Greeen Tea ",
    catagory: "Green Tea",
    image: GreenTeaLightandRefreshing,
    description: "Light and Refreshing",
    price: 7.99,
    size: "Medium",
  },
  {
    id: 21,
    name: "Ceylon Tea",
    catagory: "Green Tea",
    image: PureCeylongTea,
    description: "Pure Ceylon Tea",
    price: 8.99,
  },
  {
    id: 22,
    name: "Traditional Tea",
    catagory: "Green Tea",
    image: GreenTeaTraditionalTaste,
    description: "Green Tea Traditional Taste",
    price: 7.99,
  },
  // {
  //   id: 23,
  //   name: "Green Tea",
  //   catagory: "Green Tea",
  //   image: greeTeabags100sachets,
  //   description: "100 Tea Bags Sachets",
  //   price: 10,
  // },
  {
    id: 24,
    name: "Special Blend",
    catagory: "Green Tea",
    image: SpecialBlenTea50bags,
    description: "50 Tea Bags",
    price: 7.99,
  },
  {
    id: 25,
    name: "Early Grey Tea",
    catagory: "Green Tea",
    image: SpecialBlendTeaearlyGrey,
    description: "Special Blend",
    price: 7.99,
  },
  // {
  //   id: 26,
  //   name: "Blend Tea",
  //   catagory: "Green Tea",
  //   image: SpecialBlendTea,
  //   description: "Special Blend Tea",
  //   price: 10,
  // },
  {
    id: 27,
    name: "Super Ceylon Tea",
    catagory: "Green Tea",
    image: superCeylonCardamomGold,
    description: "Cardamom Gold",
    price: 8.99,
  },
  {
    id: 28,
    name: "Super Ceylon ",
    catagory: "Green Tea",
    image: superCeylongEarlGrayTea,
    description: "Early Grey Tea",
    price: 8.99,
  },
  {
    id: 29,
    name: "Super Ceylon",
    catagory: "Green Tea",
    image: SuperCyelongGreyTea,
    description: "Gray Tea",
    price: 6.99,
  },
  { id: 88, name: "Anise Herbs", image: aniseherbs, price: 2.99 },
  {
    id: 111,
    name: "Center Filled Fruit Chew",
    image: centerFilledFruitChew,
    price: 8.99,
  },
  { id: 112, name: "Chaomile And Mint", image: chamomileAndMint, price: 2.99 },
  { id: 113, name: "Chamomile Herbs", image: chamomileHerbs, price: 2.99 },
  { id: 117, name: "Chocolate", image: chocolate, price: 4.99 },
  {
    id: 118,
    name: "Chocolate Milk Eggs",
    image: chocolateMilkEggs,
    price: 7.99,
  },
  { id: 131, name: "Crispy Simyan", image: CrispySimyanColored, price: 5.99 },
  { id: 132, name: "Crispy Simyan", image: CrispySimyan, price: 5.99 },
  {
    id: 152,
    name: "Green Tea With Mint",
    image: greenTeaWithMint,
    price: 2.99,
  },
  { id: 154, name: "Hard Candy", image: hardCandy, price: 7.99 },
  { id: 158, name: "Hibiscus Herbs", image: hibiscusHerbs, price: 2.99 },
  { id: 168, name: "Mazafati Date", image: mazafatiDate, price: 3.99 },
  { id: 172, name: "Mongo Portakal", image: mongoportakal, price: 7.99 },
  {
    id: 198,
    name: "Rich Cinnamon Herbs",
    image: richCinnamonHerbs,
    price: 2.99,
  },
  {
    id: 214,
    name: "Soft Almond Fudge (Shirpera)",
    image: softAlmondFudgeShirpera,
    price: 7.99,
  },
  {
    id: 85,
    name: "Saffron Rock Candy",
    image: saffronrockcandy,
    price: 2.56,
  },
  {
    id: 86,
    name: "Saffron Rock Candy with Threads",
    image: saffronwiththreads,
    price: 2.56,
  },
  { id: 222, name: "Sugar Cubes", image: sugarCubes, price: 8.99 },
  { id: 224, name: "Sweets", image: sweets, price: 4.99 },
  { id: 228, name: "Thyme Herbs", image: thymeHerbs, price: 2.99 },
];
