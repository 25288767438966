import React, { useState } from "react";
import fire from "../firebase.config";
import "../styles/adminpanel.css";
import { ToastContainer } from "react-toastify";
import "../styles/toast.css";
import {
  faEnvelope,
  faSignOutAlt,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Adminpanel = () => {
  const [messages, setMessages] = useState([]);
  const [hidemsg, setHideMsg] = useState(true);
  const [order, setOrder] = useState([]);
  const getMessages = () => {
    const messagesRef = fire.database().ref("messages");

    messagesRef.on("value", (snapshot) => {
      const msgonDb = snapshot.val();
      const messagelist = [];
      for (let id in msgonDb) {
        messagelist.push({ id, ...msgonDb[id] });
      }
      setMessages(messagelist);
    });

    setHideMsg(!hidemsg);
  };

  const delorder = (corder) => {
    const orderref = fire.database().ref("order").child(corder);
    orderref.remove();
  };
  const delcontact = (contact) => {
    const contactdel = fire.database().ref("messages").child(contact);
    contactdel.remove();
  };

  const getOrder = () => {
    const customerorder = fire.database().ref("order");
    customerorder.on("value", (snapshot) => {
      const orderdb = snapshot.val();
      const orderlist = [];
      for (let id in orderdb) {
        orderlist.push({ id, ...orderdb[id] });
      }
      setOrder(orderlist);
    });
  };

  return (
    <>
      <section className="admincontroller">
        <button onClick={getMessages}>
          <FontAwesomeIcon
            className="envelop"
            icon={!hidemsg ? faEnvelopeOpen : faEnvelope}
          />
        </button>
        <h3>Customer Messages</h3>
        <button className="signoutbtn" onClick={() => fire.auth().signOut()}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          Sign Out
        </button>
      </section>
      <header className="dataheader toggle">
        <h4 className="toggle">Email Address</h4>
        <h4 className="toggle">Customer Name</h4>
        <h4 className="toggle">Subject</h4>
        <h4 className="toggle">Message</h4>
      </header>
      {!hidemsg && (
        <section className="messagesection">
          {messages.map((msg) => {
            return (
              <div key={msg.id} className="messagecontainer">
                <h3 onClick={() => alert(msg.message)}>{msg.email}</h3>
                <h3 className="toggle">{msg.fname}</h3>
                <h4 className="toggle">{msg.subject}</h4>
                <p className="toggle">{msg.message}</p>
                <button
                  onClick={() => {
                    delcontact(msg.id);
                  }}
                  className="toggle contactdel"
                >
                  Delete
                </button>
              </div>
            );
          })}
        </section>
      )}

      <main>
        <div className="ordershow">
          <button className="ordershowbtn" onClick={getOrder}>
            Show Orders
          </button>
          <h1> Customer Order </h1>
        </div>
        <div className="ordercontainer">
          {order.map((order) => {
            return (
              <div className="customercontainer" id={order.id} key={order.id}>
                <div className="customer" id={order.id} key={order.id}>
                  <h3>Customer: {order.cname}</h3>
                  <h6>Phone: {order.phonenumber}</h6>
                  <h6>Address: {order.address}</h6>
                  {order.order.map((cart) => {
                    return (
                      <div className="customerorderlist" key={cart.id}>
                        <h6 className="itemcart">{cart.name}</h6>
                        <h6>Price: {cart.price}</h6>
                        <h6>Quantity: {cart.quantity}</h6>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => delorder(order.id)}
                  className="orderDelbtn"
                >
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Adminpanel;
