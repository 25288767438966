import React, { useContext, useState } from "react";
import { CartContext } from "./Context";
import "../styles/carts.css";
import { ToastContainer, toast } from "react-toastify";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Orderprotal from "./Orderprotal";
const Carts = () => {
  const [carts, setCarts] = useContext(CartContext);
  const sendorder = () => {
    if (carts.length === 0) {
      
      return toast.info("kindly add items to your Cart first");
    }
    SetIsPortalOpen(true);
  };

  const [protalOpen, SetIsPortalOpen] = useState(false);
  const removeitemfromcart = (selecteditemincart) => {
    const updateCarts = carts.filter(
      (cart) => cart.id !== selecteditemincart.id
    );
    setCarts(updateCarts);
  };
  const delfromcart = (delfrom) => {
    const exist = carts.find((x) => x.id === delfrom.id);
    if (exist.quantity === 1) {
      removeitemfromcart(delfrom);
    } else {
      setCarts(
        carts.map((x) =>
          x.id === delfrom.id ? { ...exist, quantity: delfrom.quantity - 1 } : x
        )
      );
    }
  };
  const addtocart = (addss) => {
    const exist = carts.find((x) => x.id === addss.id);
    if (exist) {
      setCarts(
        carts.map((x) =>
          x.id === addss.id ? { ...exist, quantity: addss.quantity + 1 } : x
        )
      );
    }
  };
  const getTotalsum = () => {
    return carts.reduce(
      (sum, { price, quantity }) => sum + price * quantity,
      0
    );
  };

  return (
    <>
      <article className="totalpricesection">
        <button onClick={sendorder} className="totalpricebutton">
          Order Now
        </button>
        <Orderprotal
          open={protalOpen}
          cart={carts}
          setcart={setCarts}
          close={() => SetIsPortalOpen(false)}
        ></Orderprotal>
        <div className="pricesection">
          <h1>Total Price: ${getTotalsum().toFixed(2)}</h1>
        </div>
      </article>
      <section className="cartcontainer">
        {carts &&
          carts.map((cart) => {
            return (
              <div className="cartitems" key={cart.id}>
                <div className="group">
                  <div className="cartitemsimage">
                    <img src={cart.image} alt="Cart Item " />
                  </div>
                    <h3 className="itemname">{cart.name}</h3>
                    <h4>Price: {cart.price}</h4>
                  <div className="qtyctrl">
                    <button className="btncrtl" onClick={() => addtocart(cart)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <h4>{cart.quantity}</h4>
                    <button
                      className="btncrtl"
                      onClick={() => delfromcart(cart)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
              
                  </div>
                  <button
                      className="rmbtncrtl"
                      onClick={() => removeitemfromcart(cart)}
                    >
                      Remove from cart
                    </button>
                </div>
              </div>
            );
          })
        }
      </section>
      <ToastContainer />
    </>
  );
};

export default Carts;
