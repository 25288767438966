import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../styles/orderportal.css";
import portalimage from "../assets/afghanisofra.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "../styles/toast.css";
import fire from "../firebase.config";
function Orderprotal({ open, close, children, cart, setcart }) {
  const [customer, setCustomer] = useState({
    cname: "",
    phonenumber: "",
    address: "",
  });
  const handleOrder = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCustomer({ ...customer, [name]: value, order: cart });
  };

  const orderSend = (e) => {
    e.preventDefault();

    if (customer.cname && customer.phonenumber && customer.address) {
      const order = fire.database().ref("order");

      order.push(customer, (error) => {
        if (error) {
          toast.info("Your Order has not been sent");
        } else {
          toast.info("Your Order has been taken");
          toast.info("we will send you the product shortly");
        }
      });
      setCustomer({
        cname: "",
        phonenumber: "",
        address: "",
      });

      setcart([]);
      close();
    } else {
      toast.info("Please Fill The Form");
    }
  };
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div className="portalOverlay">
        <button className="portalbtncls" onClick={close}>
          Close
        </button>
        <div className="portalcontainer">
          {children}
          <form className="orderform">
            <label htmlFor="cname">Name:</label>
            <input
              value={customer.cname}
              placeholder="Please Type Your Name"
              onChange={handleOrder}
              type="text"
              name="cname"
            />
            <label htmlFor="phonenumber">Phone Number:</label>
            <input
              value={customer.phonenumber}
              placeholder="Your Order Numbers is Your Cellphone Number"
              onChange={handleOrder}
              type="text"
              name="phonenumber"
            />
            <label htmlFor="address">Location: </label>
            <input
              value={customer.address}
              onChange={handleOrder}
              type="text"
              name="address"
              placeholder="eg:14th Avenue, Unit B5, Markham , ON L35 OA2 "
            />
            <button type="submit" onClick={orderSend}>
              Order Now
            </button>
          </form>
        </div>
        <div className="protalimageContainer">
          <img
            className="protalimage"
            src={portalimage}
            alt="portal sofra"
          ></img>
        </div>
        <ToastContainer />
      </div>
    </>,
    document.getElementById("orderPortal")
  );
}

export default Orderprotal;
