export const searchdata = [
  {
    id: 100,
    catagory: "Saffron",
    products: [
      "Saffron",
      "Herat Saffron",
    ],
    page: "/saffron",
  },
  {
    id: 200,
    catagory: "nuts",
    products: [
      "Almond",
      "Apricot Kernel",
      "Cashews Raw Jumbo",
      "Cashews Roasted & Salted Jumbo",
      "Cashews Roasted Unsalted",
      "Ligh Salt Chickpeas",
      "Macadamia Nuts",
      "Mashaadi Chickpeas",
      "Mazari Chickpeas",
      "Pecan Fancy Mammoth Halves",
      "Pine Nuts (Jalgoza)",
      "Pistachio Kernels",
      "Pumpkin Seed",
      "Raw Blanched (Hazelnuts)",
      "Silvered Almonds",
      "Silvered Pistachios",
      "Sliced Blanched Almonds",
      "Snow White Pumpkin Seeds",
      "Sugar Coated Almonds (Nuqul)",
      "Sunflowers Seeds",
      "Walnuts Chandler (Light Halves)",
    ],
    page: "/Nuts",
  },
  {
    id: 300,
    catagory: "Dried Fruit",
    products: [
      "Afghani Green Raisins",
      "Anjeer (Fig)",
      "Apricot Slices",
      "Barberries (Zereshk)",
      "Black Mulberries",
      "Black Raisins",
      "Fig Shirazi",
      "Green Raisins (Hunza)",
      "Dried Oleastor (Sinjid)",
      "Red Raisins",
      "White Mulberries",
      "Whole Apricot",
      "Golden Raisins (Aab Josh)",
      "Golden Raisins",
      "Papaya Chunks",
      "Pineapple Rings",
      "Pistachios Turkish",
      "Prune Jumbo Pitted",
      "White Rock Candy",
      "Girdaak Raisins",
      "Apricot Rolls (Chapa Namak)",
      "Cranberries Ocean Spray",
      "Crystallized Ginger Slices",
      "Golden Plums (Alo Bokhara)",
      "Jujube (Onhab)",
      "Kiwi Slices",
      "Kashmar Green Raisins",
      "Lemon Pistachios",
      "Mango Slices",
      "Pistachio Akbari",
      "Shamsi Pistachios",
      "White Rock Canday With Threads",
    ],
    page: "/driedfruit",
  },
  {
    id: 400,
    catagory: "Bakery",
    products: [
      "Afghan Jelabi",
      "Baghlawa",
      "Uzbaki Nan",
      "White Nan",
      "Cookie",
      "Cookies",
      "Leek Bolani",
      "Kolcha",
      "Siya Nan",
      "Biscuits",
      "Coca Sandwich Biscuit",
      "Sandwich Biscuit Marshmallow Carmel Sauce",
      "Oat Barely Multi Cereal Biscuit",
      "HazelNuts Biscuits",
      "Wheat Biscuit",
      "Coca Mulberry Nectar Biscuit",
      "Mulberry Nectar Biscuits",
      "Carrot Zest & Raisins",
      "Potato Bolani",
      "Mix Bolani",
      "Root",
      "Afghani Root",
      "Sambosa (Daffy) ",
      "Turshi (Pickled Vegetables)",
    ],
    page: "/bakery",
  },
  {
    id: 500,
    catagory: "Tea",
    products: [
      "Nazo Green Tea",
      "Nazo Tea Bold & Smoth",
      "Greeen Tea ",
      "Ceylon Tea",
      "Traditional Tea",
      "Green Tea",
      "Special Blend",
      "Early Grey Tea",
      "Blend Tea",
      "Super Ceylon Tea",
      "Super Ceylon ",
      "Anise Herbs",
      "Center Filled Fruit Chew",
      "Chaomile And Mint",
      "Chamomile Herbs",
      "Chocolate",
      "Chocolate Milk Eggs",
      "Crispy Simyan",
      "Green Tea With Mint",
      "Hard Candy",
      "Hibiscus Herbs",
      "Mazafati Date",
      "Mongo Portakal",
      "Rich Cinnamon Herbs",
      "Soft Almond Fudge (Shirpera)",
      "Sugar Cubes",
      "Sweets",
      "Thyme Herbs",
      "Saffron Rock Candy",
      "Saffron Rock Candy with Threads",
    ],
    page: "/tea",
  },
  {
    id: 600,
    catagory: "Grocery",
    products: [
      "Apricot Jam",
      "Avacoda Oil",
      "Baking Powder",
      "Bandari Pickled Vegetables",
      "Basmati Rice",
      "Black Eye Beans",
      "Black Pepper",
      "Black Pepper Powder",
      "Bread Crumbs",
      "Canned Apricot",
      "Canned Chick Pea",
      "Canned Fried Vegetables",
      "Canned Lentil",
      "Cedrate Jam",
      "Canned Source Cherry",
      "Canned Salt Cucumber",
      "Chick Pea Flour",
      "Chilie Powder",
      "Chillie Sauce",
      "Cinnamon",
      "CinnamonPowder",
      "Clover Honey",
      "Coca Multi Cereals",
      "Corn Strach",
      "Cream Product",
      "Creamy Basmati Rice",
      "Darbari Rice",
      "Dill (Aneth)",
      "Distilled Cardamom Water",
      "Distilled Chicory Leaf Water",
      "Dried Herbs",
      "Dried Vegetables",
      "Extra Long Sela Basmati Rice",
      "Fig Jam",
      "Fried Chicken Mix",
      "Turmeric",
      "Getrockenete Limonen",
      "Ginger",
      "Golden Basmati Rice",
      "Haricot Beans",
      "HotPepper Crushed",
      "Jelly Powder",
      "Large Oat Flakes",
      "Lemon Powder",
      "Lime And Ginger Herbs",
      "Lime Juice",
      "Marjan",
      "Marshmallow Strawberry",
      "Marshmallow Vanilla",
      "Milk Pak",
      "Minced Pickled Vegetables",
      "Mint Water",
      "Nane",
      "NC Pasta",
      "Noorani Rice",
      "Orange Blossom Water",
      "Orange Jam",
      "Parsley",
      "Pickled Jalapeno Pepper",
      "Pickled Peppers",
      "Pickled Vegetables",
      "Plantago Seed",
      "PomeGranate Paste",
      "PomeGranate Molasses",
      "Pop Corn",
      "Premium Quality Rice",
      "Premium Tarom Rice",
      "Pumpkin Seed Half Salt",
      "Qoorot",
      "Raspberry Jam",
      "Red Kidney Beans",
      "Red Rose Rice",
      "Rice Heart Bakhti",
      "Rice Woroji",
      "Rose Flower Jam",
      "Sadaf Tamato Paste",
      "Saffron Sela Rice",
      "Pickled Vegetable Cucumber",
      "Sera Fig Jam",
      "Sesame Natural Oil",
      "Sesame Seed",
      "Shish Twook Mix",
      "Spice",
      "Strawberry Jam",
      "Sumac",
      "Super Golden Basmati Rice",
      "Table Salt",
      "Tomato Paste",
      "Turmeric",
      "UnripeGrapeJuice",
      "Vegetable Ghee",
      "Vimto Fruit Cordial",
      "Virgin Olive Oil",
      "White Kidney Beans",
      "Whole wheat",
      "Zamzam Date",
    ],
    page: "/grocery",
  },
  {
    id: 700,
    catagory: "Kitchenware",
    products: [
      "Blue Vaccum",
      "Coffee Set",
      "Feenik Thermos",
      "Glass Jug",
      "Glass Tea Pot",
      "Gold Stainless Steel Thermos",
      "Red Feenik Thermos",
      "Silver Color Teapot",
      "Silver Teapot",
      "Stainless Steel Kettle Teapot",
      "Stainless Steel Thermos",
      "Stainless Steel Water Boiler",
      "Teapot With Tea Infuser",
      "VaccumeFlaskThermos",
      "Water Boiler Tea Pot",
      "Whistling Tea Kettle",
      "White Feenik Thermos",
      "White Thermos",
    ],
    page: "/kitchenware",
  },
];
