import React, { useState } from "react";
// import {
//   GoogleMap,
//   withScriptjs,
//   withGoogleMap,
//   Marker,
//   InfoWindow,
// } from "react-google-maps";
import {
  faPhone,
  faMobile,
  faEnvelope,
  faGlobe,
  faMapMarker,
  faPaperPlane,
  // faFire,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  // faInstagram,
  // faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { maplocation } from "../data/maplocation";
import "../styles/contactus.css";
import { ToastContainer, toast } from "react-toastify";
import fire from "../firebase.config";
// function Map() {
//   const [kamiyabselected, setKamiyabSelected] = useState(false);

//   return (
//     <GoogleMap
//       defaultZoom={10}
//       defaultCenter={{ lat: 43.85531, lng: -79.25697 }}
//     >
//       {maplocation.map((location) => {
//         return (
//           <Marker
//             key={1}
//             position={{ lat: 43.85531, lng: -79.25697 }}
//             onClick={() => setKamiyabSelected(!kamiyabselected)}
//           />
//         );
//       })}
//       {kamiyabselected && (
//         <InfoWindow
//           position={{ lat: 43.85531, lng: -79.25697 }}
//           onCloseClick={() => setKamiyabSelected(null)}
//         >
//           <div className="infoWindowmap">
//             <div className="grouplogo glhstyle">
//               <FontAwesomeIcon icon={faFire} className="logoiconstyle" />
//               <div className="logoname">KAMIYAB</div>
//               <div className="logosecondname">BAKERY</div>
//             </div>
//           </div>
//         </InfoWindow>
//       )}
//     </GoogleMap>
//   );
// }
// const WrappedMap = withScriptjs(withGoogleMap(Map));

function Contactus() {
  const [person, setPerson] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (person.fname && person.email && person.subject && person.message) {
      const contactmessageRef = fire.database().ref("messages");

      contactmessageRef.push(person, (error) => {
        if (error) {
          toast.info("kindly submit the form again");
        } else {
          toast.info("Your Message has been sent to Kamiyab Super Market");
        }
      });
      toast.info("Thank You for your message , we will contact you shortly");
      setPerson({ fname: "", email: "", subject: "", message: "" });
    } else {
      toast.info("Please Fill The Form");
    }
  };

  return (
    <>
      <main className="contactuscontainer">
        <div className="contactusside">
          <h1 className="titlefirstpage">Get In Touch</h1>

          <div>
            <FontAwesomeIcon className="icons" icon={faPhone} /> 905.201.2115
          </div>
          <div>
            <FontAwesomeIcon className="icons" icon={faMobile} /> 416.771.8412
          </div>
          <div>
            <FontAwesomeIcon className="icons" icon={faMapMarker} /> 6001 14th
            Avenue. Unit B5 , Markham, On L35 OA2
          </div>
          <div>
            <FontAwesomeIcon className="icons" icon={faEnvelope} />
            kamiyabbakery@hotmail.com
          </div>
          <div>
            <FontAwesomeIcon className="icons" icon={faGlobe} />
            www.kamiyabbakery.com
          </div>
        </div>

        <div className="mapContainer">
          {/* <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC1dTXYd-N7V27FNmgKAaXDpTNYA9NHs28`}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          /> */}
                  <iframe
        title="Kamiyab"
  width="600"
  height="400"
  style={{"border":"0"}}
  loading="lazy"
  allowfullscreen
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC3BR4ik4mFhyhhDibPEReVOodWKB2rdGc&q=Kamiyab+Bakery,6001+14th+Ave+Markham+ON+L3S+0A2+Canada"
  >
</iframe>
        </div>

        <form onSubmit={handlesubmit} className="contactusform">
          <label htmlFor="name"> Name</label>
          <input
            value={person.fname}
            onChange={handleChange}
            type="text"
            name="fname"
          />
          <label htmlFor="email"> Email</label>
          <input
            value={person.email}
            onChange={handleChange}
            type="email"
            name="email"
          />
          <label htmlFor="subject"> Subject </label>
          <input
            value={person.subject}
            onChange={handleChange}
            type="text"
            name="subject"
          />
          <label htmlFor="message"> Message </label>
          <input
            value={person.message}
            onChange={handleChange}
            type="text"
            name="message"
          />
          <button type="submit">
            <FontAwesomeIcon icon={faPaperPlane} />
            Send Message
          </button>
        </form>
        <div className="socialmedia">
          <div>
            <FontAwesomeIcon icon={faFacebook} />
            <a href="https://www.facebook.com/Kamiyab-bakery-267850030227761/">
              /kamiyabBakery
            </a>
          </div>
          {/* <div>
            <FontAwesomeIcon icon={faTwitter} /> @kamiyabbakery
          </div>

          <div>
            <FontAwesomeIcon icon={faInstagram} /> /kamiyabbakery
          </div> */}
        </div>
        <ToastContainer />
      </main>
    </>
  );
}

export default Contactus;
