export const menuItems = [
  {
    id: 2,
    item: "Nuts",
    link: "/Nuts",
  },
  {
    id: 3,
    item: "Saffron",
    link: "/saffron",
  },
  {
    id: 4,
    item: "Dried Fruits",
    link: "/driedfruit",
  },
  {
    id: 5,
    item: "Tea & Sweet",
    link: "/tea",
  },
  {
    id: 6,
    item: "Bakery",
    link: "/bakery",
  },
  {
    id: 7,
    item: "Grocery",
    link: "/grocery",
  },
  {
    id: 8,
    item: "Kitchen Ware",
    link: "/kitchenware",
  },
];
