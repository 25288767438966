import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import {
  faPhone,
  faFire,
  faMobile,
  faLocationArrow,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { menuItems } from "../data/menu";
function Footer() {
  return (
    <footer>
      <div className="footermenu">
        <h4 className="footerheading">Menu</h4>
        <ul>
          {menuItems.map((items) => {
            const { id, item, link } = items;
            return (
              <Link key={id} to={link}>
                <li key={id}>{item}</li>
              </Link>
            );
          })}
        </ul>
      </div>
      <div className="footerlogo">
        <div className="grouplogo">
          <FontAwesomeIcon icon={faFire} className="logoiconstyle" />
          <div className="logoname">KAMIYAB</div>
          <div className="logosecondname">BAKERY</div>
        </div>
      </div>
      <div className="footercontactinfo">
        <h4 className="footerheading">Contact Information</h4>
        <ul>
          <li>
            <FontAwesomeIcon className="icons" icon={faPhone} /> 905.201.2115
          </li>
          <li>
            <FontAwesomeIcon className="icons" icon={faMobile} /> 416.771.8412
          </li>
          <li>
            <FontAwesomeIcon className="icons" icon={faLocationArrow} /> 6001
            14th Avenue. <br />
            Unit B5 , Markham, On L35 OA2
          </li>
          <li>
            <FontAwesomeIcon className="icons" icon={faEnvelope} />{" "}
            kamiyabbakery@hotmail.com
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
