import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { slides } from "../data/products";
import "../styles/home.css";
import React, {useContext} from "react";
import {searchdata} from '../data/homesearchdata'
import { foodservicesimage } from "../data/imagedata";
import { SearchContext } from "./SearchContext";
import Fuse from 'fuse.js'
import { Link } from "react-router-dom";
function Home() {


  const [ query] = useContext(SearchContext)
  const fuse = new Fuse(searchdata, {
    keys :[
      'catagory',
      'page',
      'products'
    ], 
    includeScore : true,
    limit: 1
  })
const searchresult= fuse.search(query)
const results = searchresult.map(result=>result.item)
  return (
    <>
    {results ?
     <side className="searchhome">
     {results.map((item)=>{
       return (
         <div key={item.id}>
           <Link to={item.page.toString()}>
           <div>{item.catagory}</div>
           </Link>
         </div>
       )
     })}
   </side> : <div></div>
    }
     
      <artical>
        <Slider autoplay={2000}>
          {slides.map((slide, index) => {
            return (
           
              <div className="slider" key={index}>
                <div className="descriptions2">
              <h2 className="slidertitle">{slide.title}</h2>
              <div>{slide.description}</div>
                </div>
              <div className="sliderimage">
                <img src={slide.path} alt="carousel" />
              </div>
              </div>
            )
          })}
        </Slider>
      </artical>
      <section className="landingpageitems">
        {foodservicesimage.map((food) => {
          return (
            <div className="services" key={food.id}>
              <h1 className="rotate">{food.text}</h1>
              <div className="foodserviceimgcontainer">
                <img
                  className="servicesImages"
                  src={food.image}
                  alt={food.image}
                />
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default Home;
