import React from "react";
import "../styles/model.css";
function Model({ selectImage, setSelectImage }) {
  const handleclick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectImage(null);
    }
  };
  return (
    <div className="backdrop" onClick={handleclick}>
      <img src={selectImage} alt="enlarge" />
    </div>
  );
}

export default Model;
