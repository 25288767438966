import { groceries } from "../data/groccery";
import "../styles/tea.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./Context";
import React, { useContext, useState } from "react";
import { SearchContext } from "./SearchContext";
import Fuse from "fuse.js";
import Model from "./Model";
function Grocery() {
  const [query] = useContext(SearchContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleselectedimage = (image) => {
    setSelectedImage(image);
  };
  const fuse = new Fuse(groceries, {
    keys: ["name", "price"],
    includeScore: true,
  });
  const searchresult = fuse.search(query);
  const results =
    query.length !== 0 ? searchresult.map((result) => result.item) : groceries;

  const [cart, setCart] = useContext(CartContext);
  const addteatocart = (selectedgroccery) => {
    let newCart = [...cart];
    let itemInCart = newCart.find((item) => selectedgroccery.id === item.id);

    if (itemInCart) {
      itemInCart.quantity++;
    } else {
      itemInCart = { ...selectedgroccery, quantity: 1 };
      newCart.push(itemInCart);
    }
    setCart(newCart);
  };

  return (
    <>
      {selectedImage && (
        <Model selectImage={selectedImage} setSelectImage={setSelectedImage} />
      )}
      <h1>Grocery Section </h1>
      <div className="teacontainer">
        {results.map((grocery) => {
          const { id, name, image, price, unit, size } = grocery;
          return (
            <div className="tea" id={id} key={id}>
              <div
                onClick={() => handleselectedimage(image)}
                className="teaimages"
              >
                <img src={image} alt={image} />
              </div>
              <div className="teadescription">
                <h2>{name}</h2>
                <h3 className="price">
                  Price : ${price} {unit && `per ${unit}`}
                </h3>
                {size && <h3>Size : {size}</h3>}
                <button
                  onClick={() => addteatocart(grocery)}
                  className="teaorderbutton"
                >
                  Add to <FontAwesomeIcon icon={faShoppingCart} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Grocery;
