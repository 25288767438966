import { nans } from "../data/products";
import "../styles/tea.css";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./Context";
import { SearchContext } from "./SearchContext";
import Fuse from "fuse.js";
import Model from "./Model";
function Bakery() {
  const [cart, setCart] = useContext(CartContext);
  const [query] = useContext(SearchContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleselectedimage = (image) => {
    setSelectedImage(image);
  };
  const fuse = new Fuse(nans, {
    keys: ["name", "price"],
  });
  const searchresult = fuse.search(query);

  const results =
    query.length !== 0 ? searchresult.map((result) => result.item) : nans;

  const addbakerytocart = (selectednan) => {
    let newCart = [...cart];
    let itemInCart = newCart.find((item) => selectednan.id === item.id);

    if (itemInCart) {
      itemInCart.quantity++;
    } else {
      itemInCart = { ...selectednan, quantity: 1 };
      newCart.push(itemInCart);
    }
    setCart(newCart);
  };
  return (
    <>
      {selectedImage && (
        <Model selectImage={selectedImage} setSelectImage={setSelectedImage} />
      )}
      <h1 className="backerysectionheading">Bakery Section</h1>
      <div className="teacontainer">
        {results.map((nan) => {
          const { id, name, image, price, unit } = nan;
          return (
            <div className="tea" key={id} id={id}>
              <div
                className="teaimages"
                onClick={() => handleselectedimage(image)}
              >
                <img src={image} alt={image} />
              </div>
              <div className="teadescription">
                <h2>{name}</h2>
                <h3 className="price">
                  Price : ${price} {unit && `per ${unit}`}
                </h3>
                <button
                  onClick={() => addbakerytocart(nan)}
                  className="teaorderbutton"
                >
                  Add to <FontAwesomeIcon icon={faShoppingCart} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Bakery;
