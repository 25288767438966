import { teas } from "../data/products";
import "../styles/tea.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./Context";
import React, { useContext, useState } from "react";
import { SearchContext } from "./SearchContext";
import Fuse from "fuse.js";
import Model from "./Model";
function Tea() {
  const [query] = useContext(SearchContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleselectedimage = (image) => {
    setSelectedImage(image);
  };
  const fuse = new Fuse(teas, {
    keys: ["name", "catagory", "image", "description", "price"],
    includeScore: true,
  });
  const searchresult = fuse.search(query);
  const results =
    query.length !== 0 ? searchresult.map((result) => result.item) : teas;

  const [cart, setCart] = useContext(CartContext);
  const addteatocart = (selectedtea) => {
    let newCart = [...cart];
    let itemInCart = newCart.find((item) => selectedtea.id === item.id);

    if (itemInCart) {
      itemInCart.quantity++;
    } else {
      itemInCart = { ...selectedtea, quantity: 1 };
      newCart.push(itemInCart);
    }
    setCart(newCart);
  };
  return (
    <>
      {selectedImage && (
        <Model selectImage={selectedImage} setSelectImage={setSelectedImage} />
      )}
      <h1>Tea Section</h1>
      <div className="teacontainer">
        {results.map((tea) => {
          const { id, name, catagory, image, description, price, size } = tea;
          return (
            <div className="tea" id={id} key={id}>
              <div
                className="teaimages"
                onClick={() => handleselectedimage(image)}
              >
                <img src={image} alt={image} />
              </div>
              <div className="teadescription">
                <h2>{name}</h2>
                <h3 className="price">Price : ${price}</h3>
               {catagory && <h4 className="catagory">Catagory: {catagory}</h4>
               } 
                {size && <h4>{size} size</h4>}
               {description && <h5>Description: {description}</h5>} 
                <button
                  onClick={() => addteatocart(tea)}
                  className="teaorderbutton"
                >
                  Add to <FontAwesomeIcon icon={faShoppingCart} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Tea;
